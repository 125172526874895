// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

/* You may have a few 'require' lines above this already */

import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("@popperjs/core")
require("stylesheets/scss/config/saas/app.scss");

import "bootstrap"
// import 'bootstrap/js/src/collapse'
// import 'bootstrap/js/src/dropdown'  
import 'bootstrap/js/src/modal'
import 'bootstrap/js/src/popover'
import 'bootstrap/js/src/toast'
// import 'bootstrap/dist/js/bootstrap'
// import "bootstrap/dist/css/bootstrap";


// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover, Toast } from "bootstrap"

// The stylesheet location we created earlier
// require("stylesheets/application.scss")
import "@fortawesome/fontawesome-free/css/all"


// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
document.addEventListener("turbolinks:load", () => {
    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
})
document.addEventListener("turbolinks:load", () => {
require("../themes/layout")
require("../themes/app")
  
})

// const feather = require("feather-icons");
import Waves from "node-waves";
const feather = require('feather-icons')
document.addEventListener("turbolinks:load", () => {
// jQuery(function () {
  feather.replace();
})
